import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Link, navigate } from 'gatsby'

if (typeof window !== 'undefined') {
  window.onload = () => {
    if (typeof ClipboardJS !== 'undefined') {
      const clipboard = new ClipboardJS('.clip');
      clipboard.on('success', function (e) {
        document.getElementById('tooltip').classList.add('show');
        setTimeout(function () {
          document.getElementById('tooltip').classList.remove('show');
        }, 4000);
        e.clearSelection();
      });
    }
  }
}

export const CartSummaryWrapper = styled.div`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
  'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
  'Segoe UI Emoji', 'Segoe UI Symbol';

  padding: 1.5rem 0;
  margin: 0 5px;
  color: #595C62;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: .1px solid #d0d0d0;
  border-top: .1px solid #d0d0d0;
  p {
    text-align: left!important;
  }
  &.no-padding {
    padding: 0 0 5px;
  }
  &.no-margin {
    margin: 0 0 10px;
  }
  a {
    color: #67471f!important;
    font-weight: bold!important;
    transition: color .2s ease-out;
    &:hover {
      text-decoration: none;
      color: #67471f;
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      display: block;
      text-align: center;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 1rem 0;
    font-size: 13px;
    margin: 0 0
  }
  
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background: #fff;
    border-bottom: 0;
    border-top: 0;
    padding: 1rem;
    > div {
      max-width: 1072px;
      margin: 0 auto;
      padding: 0 2rem;
      .description {
        display: none;
      }
      a.cartHandler {
        text-align: center;
        display: block;
        width: 100%;
      }
      > div {
        padding: 0 5px;
        @media (max-width: ${props => props.theme.breakpoints.xs}) {
          padding: 0 0;
        }
      }
    }
  }
`;

const CartInner = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
  align-items: center;
  justify-content: space-between;
  p {
    font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
    color: #1C252B;
    max-width: 700px;
    margin: 0;

    span {
      text-transform: none;
      padding-top: 5px;
      &#email {
        border-bottom: 1px solid;
        cursor: pointer;
      }
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        &.subtext {
          display: block;
          padding-bottom: 5px;
        }
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      margin-bottom: 1rem;
      font-size: 14px;
    }
    a {
      margin-left: 0;
      font-weight: normal!important;
      font-style: italic;
    }
    i {
      display: inline-block;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        display: inline;
      }
      padding-top: 5px;
      text-transform: none;
    }
  }
  strong {
    color: #67471f;
  }
`;
const Tooltip = styled.sup`
  display: inline-block;
  margin: 0;
  height: 14px;
  line-height: 14px;
  padding: 0 5px;
  background: #67471f;
  border-radius: 2px;
  margin-left: 4px;
  color: white;
  opacity: 0;
  transition: opacity .2s ease-out;
  &.show {
    opacity: 1;
  }
`;

const CartComponent = ({ count }) => (
  <CartInner>
    <div className="description">
      <p>
        <h3
          style={{ fontFamily: "lato", lineHeight: "1", marginBottom: ".5rem", color: '#67471f' }}
        >
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/murmures/")}
          >
            <span style={{ fontWeight: 400 }}>
              Exposition
            </span>{" "}
            Murmures
          </span>
        </h3>
        <h4 style={{ fontFamily: "lato", fontWeight: 400 }}>
          <small>
            3_8 septembre 2020
            <br />
            Galerie Bansard, Paris
          </small>
        </h4>
        <span className="subtext">
          Comme les traces d’une présence révolue, discrète et inspirante, qui
          dans chacun de mes gestes, se retrouve et se perd, l’exposition
          Murmures fait se raviver les parfums, les sons et les visages dont
          notre être se souvient...
        </span>
        <i>
          <br />
          If you wish to receive the complete catalog, please email me at{" "}
          <strong className="clip" data-clipboard-target="#email">
            <span id="email">dischr@gmail.com</span>
          </strong>
        </i>
        <Tooltip id="tooltip">Copied</Tooltip>
      </p>
    </div>
    <Link to={`/cart/`} className="cartHandler">
      My shopping cart ({`${count} item${count > 1 ? "s" : ""}`})
    </Link>
  </CartInner>
);

class CartSummary extends React.Component {

  state = {
    fixed: false
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener("scroll", this.onScrollEvent);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener("scroll", this.onScrollEvent)
    }
  }

  onScrollEvent = () => {
    if (!this.props.className) {
      if (window.scrollY > 215) {
        this.setState({ fixed: true })
      } else {
        this.setState({ fixed: false })
      }
    }
  }

  render() {
    const { items, className } = this.props;
    const count = items.length;
    return (
      <React.Fragment>
        {
          this.state.fixed && (
            <CartSummaryWrapper className={`fixed ${className}`}>
              <CartComponent count={count} />
            </CartSummaryWrapper>
          )
        }
        <CartSummaryWrapper className={className}>
          <CartComponent count={count} />
        </CartSummaryWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  items: state.cart
});
export default connect(mapStateToProps)(CartSummary);