import React from 'react'
import { connect } from 'react-redux'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { SEO, Header } from '../components'
import {
  ImageContainer,
  ShopImageWrapper,
  ProductInfos,
  NotAvailable,
  AddToCart,
  ProductWrapper,
  ProductInner,
  BreadCrumb,
  CadreContainer,
  ShopCadreWrapper
} from './shopItemStyle'
import CartSummary from '../components/CartSummary'
import ModalArtwork from '../components/Modal/ModalArtwork'
import InnerImageZoom from 'react-inner-image-zoom';

import { handleModalBanner } from '../actions/modal'
import { addItem, removeItem } from '../actions/cart';
import "../styles/magnify.css";

class ShopItem extends React.Component {

  componentDidMount() {
    const prismic = this.props.data.currentArtwork;
    const description = prismic.data.cadre.localFile
      ? `${prismic.data.type} - ${prismic.data.cadre.alt}`
      : prismic.data.type;

    this.data = {
      id: prismic.prismicId,
      uid: prismic.uid,
      title: prismic.data.title.text,
      description: description,
      amount: prismic.data.price
    }
  }

  removeFromCart = () => {
    this.props.removeItem(this.data);
  }

  addToCart = () => {
    this.props.addItem(this.data);
  }

  render() {
    const {
      location,
      pageContext: {
        slug,
        locale
      },
      data: { currentArtwork: { prismicId, data } },
      cartItems,
      firebaseOrders
    } = this.props;

    const context = { slug: slug, parent: 'shop' };

    const isInCart = cartItems.filter(i => i.id === prismicId).length === 1;
    const isOrdered = firebaseOrders.length > 0 && firebaseOrders.filter(i => i === prismicId).length === 1

    const formatDescription = (s) => {
      if (s) {
        const stringArray = s.split(' - ');
        return `${stringArray[1]}, ${stringArray[2] ? `${stringArray[2]}, ${stringArray[0]}` : `${stringArray[0]}`}`;
      }
    }
    return (
      <React.Fragment>
        <SEO
          title={`Disch Rémy - ${data.title.text}`}
          desc={formatDescription(data.meta_description)}
          pathname={location.pathname}
          locale={locale}
          banner={data.item.localFile.childImageSharp.fixed.src}
        />
        <Header {...context} />

        <ProductWrapper>
          {/* <CartSummary className="no-margin" /> */}
          <BreadCrumb>
            <ul>
              <li>
                <Link to={`/shop/`}>Shop</Link>
              </li>
              <li>
                <strong>{data.title.text}</strong>
              </li>
            </ul>
          </BreadCrumb>
          <ProductInner>
            <ImageContainer>
              <ShopImageWrapper>
                <InnerImageZoom
                  src={data.item.localFile.childImageSharp.fluid.src}
                  zoomSrc={data.image_hd.localFile.childImageSharp.original.src}
                />
              </ShopImageWrapper>
            </ImageContainer>

            <ProductInfos className={isOrdered ? "isOrdered" : ""}>
              <h2>
                <div>{data.title.text}</div>
                {(JSON.parse(data.available) === false || isOrdered) && (
                  <NotAvailable />
                )}
              </h2>
              {data.year && (
                <span className="year">
                  Year: <strong>{data.year.uid}</strong>
                </span>
              )}
              <span className="categories">
                <small>categories: </small>
                <small>
                  <Link to={`/works/`}>#works</Link>
                </small>
                {data.categories.map((item, index) => {
                  return (
                    <small key={`tag-${index}`}>
                      <Link to={`/${item.category.uid}/`}>
                        #{item.category.document[0].data.name}
                      </Link>
                    </small>
                  );
                })}
                {data.year && (
                  <small>
                    <Link to={`/works/${data.year.uid}/`}>
                      #{data.year.uid}
                    </Link>
                  </small>
                )}
              </span>
              <span className="type">{data.type}</span>
              <span className="shipping">Shipping fee included</span>
              <AddToCart>
                <div className="price">{data.price}.00 €</div>
                <span className="isOrdered">
                  On demand, email me at dischr@gmail.com
                </span>
              </AddToCart>

              {data.cadre.localFile && (
                <CadreContainer>
                  <ShopCadreWrapper>
                    <Img
                      alt={formatDescription(data.meta_description)}
                      fluid={data.cadre.localFile.childImageSharp.fluid}
                      placeholderStyle={{
                        filter: "grayscale(100%) contrast(100%)",
                        imageRendering: "pixelated",
                        opacity: 0,
                      }}
                    />
                  </ShopCadreWrapper>
                  <span>{data.cadre.alt}</span>
                </CadreContainer>
              )}
            </ProductInfos>
          </ProductInner>
        </ProductWrapper>
        <ModalArtwork
          image={{
            title: data.title.text,
            file: data.item.localFile.childImageSharp.fixed,
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart,
  firebaseOrders: state.order.items,
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
  removeItem: (item) => dispatch(removeItem(item)),
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(ShopItem);

export const pageQuery = graphql`
  query ShopItemPage($uid: String!, $locale: String!) {
    currentArtwork: prismicArtwork(uid: { eq: $uid }, lang: { eq: $locale }) {
      prismicId
      uid
      data {
        meta_description
        meta_title
        title {
          text
        }
        type
        available
        on_demand
        price
        item {
          alt
          localFile {
            childImageSharp {
              fixed(height: 800, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        cadre {
          alt
          localFile {
            childImageSharp {
              fixed(height: 800, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_hd {
          localFile {
            childImageSharp {
              original {
                height
                src
                width
              }
            }
          }
        }
        categories {
          ... on PrismicArtworkDataCategories {
            category {
              uid,
              document {
                data {
                  name
                }
              }
            }
          }
        }
        year {
          ... on PrismicArtworkDataYear {
            uid
          }
        }
      }
    }
  }
`