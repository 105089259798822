import styled from '@emotion/styled'
import { Wrapper } from '../components'

export const ImageContainer = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  ${'' /* overflow: hidden; */}
  padding: 0 15px 0 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    padding: 0;
  }
`;

export const ShopImageWrapper = styled.div`
  position: relative;
  ${'' /* width: 100%; */}
  height: auto;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    .overlay {
      visibility:visible;
    }
  }
  .gatsby-image-wrapper {
    display: block!important;
    width: 100%!important;
    height: 100%!important;
  }
`

export const CadreContainer = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  overflow: hidden;
  padding: 0 15px 0 0;
  margin-top: 50px;
  span {
    font-size: 14px;
    display: block;
    ${'' /* width: 75%; */}
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    padding: 0;
  }
`;

export const ShopCadreWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    .overlay {
      visibility:visible;
    }
  }
  .gatsby-image-wrapper {
    display: block!important;
    width: 100%!important;
    height: 100%!important;
  }
`

export const ProductInfos = styled.div`
  width: 50%;
  padding: 15px;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    padding: 15px 0;
  }

  &.isOrdered {
    h2 div, > span, .price {
      opacity: .6
    }
  }

  h2 {
    font-family: 'Roboto',sans-serif;
    display: block;
    padding: 0;
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    color: #67471f;
    div {
      display: inline
    }
  }

  span {
    display: block;
    margin: 10px 0;
    &.year {
      margin-bottom: 0;
      font-size: 14px;
      color: #999;
    }
    &.categories {
      margin-top: 0;
      font-size: auto;
      color: #999;
      small {
        display: inline-block;
        margin-right: 10px;
        a {
          color: #999;
          font-weight: bold;
        }
      }
    }
    &.type {
      min-height: 40px;
      font-size: 18px;
    }
    &.shipping {
      font-size: 14px
    }
  }
`;

export const NotAvailable = styled.span`
  background: #ff1d01;
  width: 12px;
  height: 12px;
  display: block;
  float: right;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 10px;
`
export const AddToCart = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0;
  margin-top: 3rem;

  > .price {
    font-size: 24px;
  }

  button {
    font-family: 'Roboto',sans-serif;
    border: 0;
    border-bottom: 1px solid #494949;
    cursor: pointer;
    transition: color .2s ease-out;
    font-weight: 100;
    background: none;
    outline: none;
    &:hover {
      color: #999999;
      border-bottom: 1px solid #999999;
    }
    &.alreadyInCart {
      font-size: 12px;
      margin-top: 5px;
      padding-bottom: 6px;
    }
    &.isOrdered {
      cursor: default;
      color: #999999;
      border-bottom: 1px solid #999999;
    }
  }
`;

export const ProductWrapper = Wrapper.withComponent('main')
export const ProductInner = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;
export const BreadCrumb = styled.div`
font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  ul {
    margin: 0;
    li {
      margin: 0;
      padding-bottom: 15px;
      list-style-type: none;
      display: inline-block;
      font-size: 14px;
      color: #595C62;
      :not(:last-of-type) {
        &:after {
          display: inline-block;
          content: "/";
          padding: 0 5px;
        }
      }
    }
  }
`;